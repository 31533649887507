<template>
    <section  id="sets">

        <div class="set-div  center"

             :class="{visible: isVisible}">


        <h2  class="center">Ягодные наборы</h2>
        <p class="center"> от 990р.</p>

        <img class="img1" src="../../../public/img/set1.png" alt="">
        <img class="img2" src="../../../public/img/set4.png" alt="">




        <div class="toSetsDiv" >
        </div>

            <span class="toSetsSpan center" @click="toSets"
                  v-waypoint="{ active: true, callback: onWaypoint, options: intersectionOptions }">
                Смотреть
            </span>
                   



            </div>




    </section>

</template>

<script>
    export default {
        name: 'thirdSection',


        data: ()=>({



            isVisible: false,
            intersectionOptions: {
                root: null,
                rootMargin: '0px 0px 0px 0px',
                thresholds: [0]
            },

        }),


        methods:{
            onWaypoint ({ going, direction }) {
                // going: in, out
                // direction: top, right, bottom, left
                if (going === this.$waypointMap.GOING_IN) {

                    setTimeout(()=>{
                        this.isVisible = true;
                    }, 500)

                }

                if (direction === this.$waypointMap.DIRECTION_TOP) {
                    setTimeout(()=>{
                        this.isVisible = true;
                    }, 500)

                }
            },
            toSets(){

                window.scroll({
                    top: 200,
                    left: 0,
                });
                this.$router.push('/sets')

            }
        }
    }

</script>

<style scoped lang="less">

    @text-color: rgb(26,26, 24);
    @white: rgb(255,255, 255);
    @red: #f35858;
    @black: #414141;
    @blackgray: #3D3D3D;





    #sets{
        margin-top: 20vh;
        .set-div{

            position: relative;
            height: 100vh;

                h2{
                    text-align: center;
                    font-weight: bold;
                    font-size: 2.5rem;
                    color: @black;
                    transition: 0.25s;
                    z-index: 300;
                }


                p{
                    margin-left: auto;
                    margin-right: auto;
                    left: 0;
                    right: 0;
                    transition-delay: 1s;
                    position: absolute;
                    z-index: 350;
                    font-style: italic;
                    text-align: center;
                    font-weight: 800;
                    letter-spacing: 2px;
                    color: #fd5949;
                    font-size: 3.2rem;
                    -webkit-transition: all .1s ease-out;
                    -moz-transition: all .1s ease-out;
                    -o-transition: all .1s ease-out;
                    -ms-transition: all .1s ease-out;
                    transition: all .1s ease-out;
                    opacity: 0;
                }


            .toSetsDiv{
                width: 100%;
                height: 100%;
                background: rgba(255, 255, 255, 0);
                position: absolute;
                top: 10%;
                z-index: 300;
                -webkit-transition: all .5s ease-out;
                -moz-transition: all .5s ease-out;
                -o-transition: all .5s ease-out;
                -ms-transition: all .5s  ease-out;
                transition: all .5s  ease-out;

            }

            .toSetsSpan{
                text-align: center;
                -webkit-transition-delay: 1.5s;
                transition-delay: 1.5s;
                cursor: pointer;
                -webkit-transition: all .5s ease-out;
                -moz-transition: all .5s ease-out;
                -o-transition: all .5s ease-out;
                -ms-transition: all .5s  ease-out;
                transition: all .5s  ease-out;

                letter-spacing: 5px;
                font-weight: 600;

                opacity: 0;
                transform: scale(0);
                visibility: hidden;

                text-transform: uppercase;
                box-shadow: 0 0 10px rgba(0,0,0,0.3);

                background-color: #fd5949;
                color:  white;
                border-radius: 5px;

                position: absolute;
                z-index: 600;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
            }

            .img1{
                z-index: 250;
                transition-delay: .8s;
                -webkit-transition: all  .95s ease-out;
                -moz-transition: all  .95s ease-out;
                -o-transition: all   .95s ease-out;
                -ms-transition: all  .95s ease-out;
                transition: all .95s ease-out;
            }

            .img2{
                z-index: 250;
                transition-delay: .8s;
                -webkit-transition: all  .95s ease-out;
                -moz-transition: all  .95s ease-out;
                -o-transition: all   .95s ease-out;
                -ms-transition: all  .95s ease-out;
                transition: all .95s ease-out;
            }




        }

        .visible {

            .toSetsDiv{
                background: rgba(255, 255, 255, 0.65);
                z-index: 300;
                -webkit-transition: all .5s ease-out;
                -moz-transition: all .5s ease-out;
                -o-transition: all .5s ease-out;
                -ms-transition: all .5s  ease-out;
                transition: all .5s  ease-out;

            }

            .toSetsSpan {
                transform: scale(1);
                opacity: 1;
                visibility: visible;
                z-index: 350;
                cursor: pointer;
                -webkit-transition-delay: 1.5s;
                transition-delay: 1.5s;
                -webkit-transition: all .5s ease-out;
                -moz-transition: all .5s ease-out;
                -o-transition: all .5s ease-out;
                -ms-transition: all .5s  ease-out;
                transition: all .5s  ease-out;

            }

            p{
                -webkit-transition: all .1s ease-out;
                -moz-transition: all .1s ease-out;
                -o-transition: all .1s ease-out;
                -ms-transition: all .1s ease-out;
                transition: all .1s ease-out;
                transition-delay: 1.3s;
            }

            .img1 {
                transition-delay: .8s;
                -webkit-transition: all  .95s ease-out;
                -moz-transition: all  .95s ease-out;
                -o-transition: all   .95s ease-out;
                -ms-transition: all  .95s ease-out;
                transition: all .95s ease-out;
            }

            .img2 {
                transition-delay: .8s;
                -webkit-transition: all  .95s ease-out;
                -moz-transition: all  .95s ease-out;
                -o-transition: all   .95s ease-out;
                -ms-transition: all  .95s ease-out;
                transition: all .95s ease-out;
            }
        }


        @media screen and (max-width: 600px) {

            p{
                transform: translateX(-15%);
                font-size: 3rem;
                opacity: 0;
            }
            .toSetsSpan{
                font-size: 1.5em;
                padding-top: 1.4rem;
                top: 40%;
                height: 5rem;
                width: 80vw;
                max-width: 15rem;
                min-width: 9rem;
            }

            .img1{
                top:25%;
                position: absolute;
                left: 0;
                width: 60vw;
            }

            .img2{
                top:30%;
                position: absolute;
                right: 0;
                width: 50vw;
            }

            .visible{
                .img1{
                    left: -25%;
                }
                .img2{
                    top: 30%;
                    width: 25vw;
                }



            }
        }


        @media screen and (min-width: 600px)  and (max-width: 900px){
            .img1{
                top:20%;
                position: absolute;
                left: 0;
                width: 40vw;

            }
            p{
                transform: translateX(-5%rem);
                font-size: 3.5rem!important;
                font-weight: 900!important;
                opacity: 0;
            }
            .img2{

                top:25%;
                position: absolute;
                right: 0;
                width: 30vw;
            }
            .toSetsSpan{
                font-size: 1.7em;
                padding-top: 1.3rem;
                top: 45%;
                height: 5rem;
                font-weight: 600!important;
                width: 80vw;
                max-width: 18rem;
                min-width: 15rem;
            }

            .visible{
                .img1{
                    top:20%;
                    left: -10%;
                }
                .img2{

                    top:25%;
                    width: 20vw;
                }



            }
        }






        @media screen and (min-width: 900px) and (max-width: 1500px) {
            h2{
                font-size: 4rem;
            }
            .img1{
                top:15%;
                position: absolute;
                left: 0;
                width: 30vw;

            }

            p{
                top: 10%;
                font-size: 3.2rem!important;
                font-weight: 900!important;
                transform: translateX(-15%);
                opacity: 0;
            }


            .img2{
                top:10%;
                position: absolute;
                right: 0;
                width: 25vw;

            }
            .toSetsSpan{
                top: 40%;
                font-weight:600!important;
                font-size:  1.5rem;
                height: 5rem;
                padding-top: 1.5rem;
                width: 80vw;
                max-width: 19rem;
                min-width: 16rem;

            }

            .visible{
                .img1{
                    left: -10%;
                    top:10%;
                }
                .img2{

                    top: 15%;
                    width: 15vw;
                }



            }
        }


        @media screen and (min-width: 1501px) {
            h2{
                font-size: 4rem;
            }
            .img1{
                top:15%;
                width: 29vw;
                position: absolute;
                left: 0;
            }

            .img2{
                top:15%;
                position: absolute;
                right: 0;
                width: 18vw;
            }

            p{
                top: 10%;
                font-size: 3.3rem!important;
                font-weight: 900!important;
                transform: translateX(-15%);
                opacity: 0;
            }



            .toSetsSpan{
                top: 45%;
                font-weight:600!important;
                font-size:  1.8rem;
                height: 5rem;
                padding-top: 1.2rem;
                width: 80vw;
                max-width: 20rem;
                min-width: 19rem;

            }


            .visible{
                .img1{
                    left: -5%;
                    top:19%;
                    width: 20vw;
                }
                .img2{
                    top: 19%;
                    width: 10vw;
                }

            }
        }



        .visible{

            p{
                transform: translateX(0);
                opacity: 1;

            }
            .toSetsDiv{

                opacity: 1;
            }

            .toSetsSpan{
                opacity: 1;

                visibility: visible;

            }
        }





    }





</style>