<template>
    <section  id="chocolate">

        <div class="chocolate-row"

             :class="{visible: isVisible}">

            <img src="../../../public/img/chocko1.png" alt="">
                <span class=""
                      v-waypoint="{ active: true, callback: onWaypoint, options: intersectionOptions }"
                >
                    Бельгийский шоколад
                </span>


            
        </div>

    </section>

</template>

<script>
    export default {
        name: 'section4',

        data: ()=>({
            isVisible: false,

            intersectionOptions: {
                root: null,
                rootMargin: '0px 0px 0px 0px',
                thresholds: [0]
            },

        }),


        methods:{
            onWaypoint ({ going, direction }) {
                // going: in, out
                // direction: top, right, bottom, left
                if (going === this.$waypointMap.GOING_IN) {
                    this.isVisible = true;
                }

                if (direction === this.$waypointMap.DIRECTION_TOP) {
                    this.isVisible = true;
                }
            },

        }
    }

</script>

<style scoped lang="less">

    @text-color: rgb(26,26, 24);
    @white: rgb(255,255, 255);
    @red: #f35858;
    @black: #414141;
    @blackgray: #3D3D3D;


    .chocolate-row{
        margin-top: 10rem;
        position: relative;
        height: 110vh;
        background-color: #ec4559;

        span{
            transform: scale(0);
            opacity: 0;
            left: 0;
            text-shadow: 1px 2px 30px rgba(255,255, 255, 0.7);
            position: absolute;
            text-transform: uppercase;
            color: white;
            -webkit-transition: all .25s ease-in-out;
            -moz-transition: all .25s ease-in-out;
            -o-transition: all .25s ease-in-out;
            -ms-transition: all .25s ease-in-out;
            transition: all .25s ease-in-out;
            transition-delay: .85s;
        }

        img{
            -webkit-transition: all .86s ease-out;
            -moz-transition: all .86s ease-out;
            -o-transition: all .86s ease-out;
            -ms-transition: all .86s ease-out;
            transition: all .86s ease-out;
            transition-delay: .165s;
            position: absolute;
            bottom: 0;

        }

    }

    @media screen and (max-width: 600px){

        img{
            position: absolute;
            bottom: 10%;
            right: 0;
            width: 99vw;

        }

        span{
            float: left;
            text-align: left;
            -moz-text-align-last: left;
            text-align-last:left;
            top: 25vh;
            left:-35%;
            letter-spacing: 4px;
            font-weight: 600;
            line-height: 8rem;
            width: 80vw;
            font-size: 1.7rem;

        }

        .visible{
            img{
                transform: scale(0.8) translateY(8vh) translateX(5vw);

            }

            span{
                transform: translateX(10%);
                opacity: 1;
            }

        }
    }

    @media screen and (min-width: 600px) and  (max-width: 1000px){

        img{
            bottom: 0;
            position: absolute;
            right: 0;
            width: 99vw;

        }

        span{

            top: 0;
            left: 0;
            letter-spacing: 4px;
            font-weight: 600;
            line-height: 8rem;
            width: 50vw;
            font-size: 2.5rem;

        }

        .visible{
            img{
                transform: scale(0.8) translateY(20vh) translateX(10vw);
            }

            span{
                transform: scale(1) translateY(15vh) translateX(20vw);
                opacity: 1;
            }

        }
    }

    @media screen and (min-width: 1000px) and  (max-width: 1500px){

        img{
            bottom: 0;
            position: absolute;
            right: 5vw;
            width: 59vw;

        }

        span{
            top: 20vh;
            letter-spacing: 4px;
            font-weight: 600;
            line-height: 10rem;
            width: 50vw;
            font-size: 3rem;

        }

        .visible{
            img{

                transform: scale(0.8) translateY(20vh) translateX(5vw);

            }

            span{
                transform: scale(1) translateX(10vw);
                opacity: 1;
            }

        }
    }

    @media screen and (min-width: 1500px){

        img{
            bottom: 0;
            position: absolute;
            right: 5vw;
            width: 50vw;

        }

        span{
            top: 25vh;
            left: 10%;

            font-weight: 600;
            line-height: 10rem;
            width: 10rem;
            font-size: 3.5rem;

        }

        .visible{
            img{
                transform: scale(0.8) translateY(20vh) translateX(1vw);
            }

            span{
                transform: scale(1) translateX(10vw);
                opacity: 1;




            }

        }
    }


</style>