<template>
    <section  id="strawberry">
        <h3 class="center">
            Почему это<p> отличный подарок?</p>
        </h3>
        <div class="strawberry-row"

             :class="{visible: isVisible}">


        <img src="../../../public/img/strawberry.jpg" alt="">
            <span class=""
                  v-waypoint="{ active: true, callback: onWaypoint, options: intersectionOptions }"
            >
                Свежая клубника
            </span>



        </div>

    </section>

</template>

<script>
    export default {
        name: 'section5',

        data: ()=>({
            isVisible: false,

            intersectionOptions: {
                root: null,
                rootMargin: '0px 0px 0px 0px',
                thresholds: [0]
            },

        }),


        methods:{
            onWaypoint ({ going, direction }) {
                // going: in, out
                // direction: top, right, bottom, left
                if (going === this.$waypointMap.GOING_IN) {
                    this.isVisible = true;
                }

                if (direction === this.$waypointMap.DIRECTION_TOP) {
                  this.isVisible = true;
                }
            },

        }

    }

</script>

<style scoped lang="less">

    @text-color: rgb(26,26, 24);
    @white: rgb(255,255, 255);
    @red: #f35858;
    @black: #414141;
    @blackgray: #3D3D3D;



    #strawberry{
        margin-top: 20vh;
        h3{
            padding: 2rem;
            font-weight: bolder;
            font-size: 3rem;
            p{
                font-weight: 300;

            }
        }
    }
    .strawberry-row{

        position: relative;
        height: 70vh;
        background-color: white;

        span{
          letter-spacing: 4px;
            font-weight: 600;
            padding: 1rem;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            line-height: 5rem;
            text-transform: uppercase;
            text-align: center;
            color: #343434;
            position: absolute;
            font-weight:bolder;
            -webkit-transition: all .3s ease-in-out;
            -moz-transition: all .3s ease-in-out;
            -o-transition: all .3s ease-in-out;
            -ms-transition: all .3s ease-in-out;
            transition: all all .3s ease-in-out;
        
            transition-delay: 1s;
        }

        img{
            transform: scale(1.3);
            position: absolute;
            height: 25rem;
            bottom: 0;
            -webkit-transition: all .76s ease-out;
            -moz-transition: all .76s ease-out;
            -o-transition: all .76s ease-out;
            -ms-transition: all .76s ease-out;
            transition: all .76s ease-out;
            transition-delay: .465s;
        }

        .visible{
            img{
             -webkit-transition: all .76s ease-out;
            -moz-transition: all .76s ease-out;
            -o-transition: all .76s ease-out;
            -ms-transition: all .76s ease-out;
            transition: all .76s ease-out;
            transition-delay: .865s;
            }

            span{
                -webkit-transition: all .3s ease-in-out;
                -moz-transition: all .3s ease-in-out;
                -o-transition: all .3s ease-in-out;
                -ms-transition: all .3s ease-in-out;
                transition: all all .3s ease-in-out;
             
                opacity: 1;
                visibility: visible;
              

            }

        }
    }




    @media screen and (max-width: 600px){

        img{
            position: absolute;
            top: 10vh;

            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 1%;
        }

        span{
            visibility: visible;
            transition-delay: 1s;
            top: 60%;
            opacity: 0;
            visibility: visible;
            letter-spacing: 4px;
            font-size: 1.6rem;
            font-weight: 600;
            text-shadow: 0px 0px 20px white;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            transform: scale(1)!important;
        }

        .visible{
            img{
                transform: scale(0.8)  translateY(-17vh);
            }

            span{
                transition-delay: 1s;
                opacity: 1;
        
            }

        }
    }


    @media screen and (min-width: 600px) and (max-width: 1000px) {

        img{
            position: absolute;
            top: 17vh;
            transform: scale(1.3);

            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
        }

        span{
            opacity: 0;
            visibility: hidden;
            letter-spacing: 4px;
            font-weight: 600;
            font-size: 1.3rem;
            padding: 1rem;
            width: 15rem;
            text-shadow: 0px 0px 20px white;
            top: 28vh;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            transform: scale(0);
        }

        .visible{
            img{

                transform: scale(.95) translateX(-25vw);
                /*filter: blur(3px);*/
            }

            span{

                transform: scale(1.5)  translateX(15vw);
                opacity: 1;
                visibility: visible;

                right: 1%;

            }

        }
    }



    @media screen and (min-width: 1000px) and (max-width: 1300px){
        img{
            position: absolute;
            top: 17vh;
            transform: scale(1.5);

            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
        }

        span{
            letter-spacing: 4px;
            font-size: 1.5rem;
            padding: 1rem;
            width: 15rem;
            opacity: 0;
            visibility: hidden;
            letter-spacing: 4px;
            font-weight: 600;

            text-shadow: 0px 0px 20px white;
            top: 28vh;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            transform: scale(0);
        }

        .visible{
            img{

                transform: scale(1.2) translateX(-20vw);
                /*filter: blur(3px);*/
            }

            span{

                transform: scale(1.5)  translateX(15vw);


                opacity: 1;
                visibility: visible;

            }

        }
    }



    @media screen and (min-width: 1300px) and (max-width: 1600px){

        img{
            position: absolute;
            top: 17vh;
            transform: scale(1.5);

            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
        }

        span{


            opacity: 0;
            visibility: hidden;
            letter-spacing: 4px;
            font-weight: 600;

            text-shadow: 0px 0px 20px white;
            top: 28vh;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            transform: scale(0);
        }

        .visible{
            img{

                transform: scale(1.2) translateX(-20vw);
                /*filter: blur(3px);*/
            }

            span{

                transform: scale(1.5)  translateX(15vw);


                opacity: 1;
                visibility: visible;
                letter-spacing: 4px;
                font-weight: 600;
                font-size: 1.5rem;
                padding: 1rem;
                right: 1%;
                width: 15rem;
            }

        }
    }

    @media screen and (min-width: 1600px){

        img{
            position: absolute;
            top: 17vh;
            transform: scale(2);

            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
        }

        span{


            opacity: 0;
            visibility: hidden;
            letter-spacing: 4px;
            font-weight: 600;

            text-shadow: 0px 0px 20px white;
            top: 28vh;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            transform: scale(0);
        }

        .visible{
            img{

                transform: scale(1.4) translateX(-20vw);
                /*filter: blur(3px);*/
            }

            span{

                transform: scale(1.5)  translateX(15vw);


                opacity: 1;
                visibility: visible;
                letter-spacing: 4px;
                font-weight: 600;
                font-size: 1.5rem;
                padding: 1rem;
                right: 1%;
                width: 15rem;
            }

        }
    }

</style>