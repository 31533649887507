<template>
	<section id="types">
		<div class="">
			<div class="row types">
				<div class="section_header text-center">
					<h2 class="center">Клубничные букеты</h2>
				</div>
			</div>

			<div id="bouquets-category">
				<div class=" card-line row ">
					<div class="card-b col s12 m4 l4 xl4">
						<div class="bouquet-card">
							<img
								src="./../../../public/img/backs/str.jpg"
								alt=""
								class="z-depth-5 responsive-img responsive-img"
							/>
							<div
								@click="to('/mini')"
								class="center center-block center-align c-title"
							>
								Мини
							</div>
						</div>
					</div>

					<div class="card-b col s12 m4 l4 xl4">
						<div class="bouquet-card">
							<img
								src="./../../../public/img/backs/str.jpg"
								alt=""
								class="z-depth-5 responsive-img responsive-img"
							/>

							<div
								@click="to('/medium')"
								class="center center-block center-align c-title"
							>
								Средние
							</div>
						</div>
					</div>

					<div class="card-b col s12 m4 l4 xl4">
						<div class="bouquet-card">
							<img
								src="./../../../public/img/backs/str.jpg"
								alt=""
								class="z-depth-5 responsive-img responsive-img"
							/>

							<div
								@click="to('/large')"
								class="center center-block center-align c-title"
							>
								Большие
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'secondSection',
	methods: {
		to(p) {
			window.scroll({
				top: 200,
				left: 0,
			});
			this.$router.push(p);
		},
	},
};
</script>

<style scoped lang="less">
@text-color: rgb(26, 26, 24);
@white: rgb(255, 255, 255);
@red: #f35858;
@black: #343434;
@blackgray: #3d3d3d;
@height: 35vh;

section#types {
	.types {
		h2 {
			letter-spacing: 3px;
			font-weight: bold;
			font-size: 2.5rem;
			color: @black;
			padding-top: 120px;
		}
	}
}
#bouquets-category {
	.card-line {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		align-content: center;

		.card-b {
			-webkit-transition: all 0.2s ease-in-out;
			-moz-transition: all 0.2s ease-in-out;
			-o-transition: all 0.2s ease-in-out;
			-ms-transition: all 0.2s ease-in-out;
			transition: all 0.2s ease-in-out;
            cursor: pointer;

			.bouquet-card {
				position: relative;
				-webkit-transition: all 0.2s ease-in-out;
				-moz-transition: all 0.2s ease-in-out;
				-o-transition: all 0.2s ease-in-out;
				-ms-transition: all 0.2s ease-in-out;
				transition: all 0.2s ease-in-out;
				display: flex; // make us of Flexbox
				align-items: center;
				img {
					-webkit-transition: all 0.2s ease-in-out;
					-moz-transition: all 0.2s ease-in-out;
					-o-transition: all 0.2s ease-in-out;
					-ms-transition: all 0.2s ease-in-out;
					transition: all 0.2s ease-in-out;
					opacity: 1;
					z-index: 500;
				}

				.c-title {
					-webkit-transition: all 0.2s ease-in-out;
					-moz-transition: all 0.2s ease-in-out;
					-o-transition: all 0.2s ease-in-out;
					-ms-transition: all 0.2s ease-in-out;
					transition: all 0.2s ease-in-out;
					display: flex; // make us of Flexbox
					align-items: center; // does vertically center the desired content
					justify-content: center; // horizontally centers single line items
					text-align: center;
					position: absolute;
					left: 0;
					right: 0;
					margin-left: auto;
					margin-right: auto;
					height: 28%;
					font-weight: 700;
					letter-spacing: 3px;

					-webkit-font-smoothing: subpixel-antialiased;
					text-rendering: optimizeLegibility;
					white-space: nowrap;
					text-transform: uppercase;
					color: #000;
					text-align: center;
					border-radius: 2px;
					z-index: 600;
					background-color: white;

					opacity: 0.8;
				}
				&:hover {
					img {
						-webkit-transition: all 0.2s ease-in-out;
						-moz-transition: all 0.2s ease-in-out;
						-o-transition: all 0.2s ease-in-out;
						-ms-transition: all 0.2s ease-in-out;
						transition: all 0.2s ease-in-out;
						opacity: 0.8;
						transform: scale(1.1);
					}

					.c-title {
						-webkit-transition: all 0.2s ease-in-out;
						-moz-transition: all 0.2s ease-in-out;
						-o-transition: all 0.2s ease-in-out;
						-ms-transition: all 0.2s ease-in-out;
						transition: all 0.2s ease-in-out;
						opacity: 0.9;
						transform: scale(1.3);
					}
				}
			}
		}
	}
}

@media screen and (max-width: 601px) {
	#bouquets-category {
		padding: 3%;
	}
	.card-b {
		padding: 10%;

		.bouquet-card {
			.c-title {
				font-size: 180%;
				font-weight: 600 !important;
				width: 70% !important;
			}
		}
	}
}

@media screen and (min-width: 601px) and (max-width: 900px) {
	#bouquets-category {
		padding: 12px;
	}

	.card-b {
		padding: 12px;

		.bouquet-card {
			.c-title {
				width: 80%;
				font-size: 120%;
			}
		}
	}
}

@media screen and (min-width: 901px) and (max-width: 1400px) {
	#bouquets-category {
		padding: 28px;
	}

	.card-b {
		padding: 20px;

		.bouquet-card {
			.c-title {
				width: 80%;
				font-size: 140%;
			}
		}
	}
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
	#bouquets-category {
		padding: 5%;
	}

	.card-b {
		padding: 5%;

		.bouquet-card {
			height: 20rem;

			.c-title {
				width: 70% !important;
				font-size: 1.7rem;
			}
		}
	}
}

@media screen and (min-width: 1601px) {
	#bouquets-category {
		padding: 5%;
	}

	.card-b {
		padding: 5%;

		.bouquet-card {
			.c-title {
				width: 65% !important;
				font-size: 1.7rem !important;
			}
		}
	}
}
</style>
