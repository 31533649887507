<template>
	<section id="social">
		<h4 class="center">Свяжитесь с нами</h4>

		<div class="connect-row row center text-center">
			<div class="connect-item instagram col s12 m4 l4 xl4">
				<a :href="info.instagram">
					<i class="fab fa-instagram"> </i>
					<p>Instagram</p>
				</a>
			</div>

			<div class="connect-item telegram col s12 m4 l4 xl4 ">
				<a :href="info.telegram">
					<i class="fab fa-telegram"></i>
					<p>Telegram</p>
				</a>
			</div>

			<div class="connect-item whatsapp col s12 m4 l4 xl4">
				<a :href="info.whatsapp">
					<i class="fab fa-whatsapp"></i>
					<p>WhatsApp</p>
				</a>
			</div>
		</div>

		<!-- <div class="row center text-center phone_number_footer">
			<h2>
				<a :href="info.phone"
					>{{ info.mainPhone }} <i class="fas fa-phone-volume" />
				</a>
			</h2>
		</div> -->
	</section>
</template>

<script>
export default {
	name: 'socialFooter',

	computed: {
		info() {
			return this.$store.getters.info;
		},
	},
};
</script>

<style scoped lang="less">
@text-color: rgb(26, 26, 24);
@white: rgb(255, 255, 255);
@red: #f35858;
@black: #414141;
@blackgray: #3d3d3d;

section#social {
	margin-top: 10rem;
	h4 {
		font-weight: 500;
		font-size: 2.5rem;
		color: @black;
		padding-bottom: 0.7rem;
	}
}
.connect-row {
	padding: 0 3rem 0 3rem;
	-webkit-transition: all 0.2s ease-out;
	-moz-transition: all 0.2s ease-out;
	-o-transition: all 0.2s ease-out;
	-ms-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out;
	font-size: 5rem;
}
.connect-item {
	cursor: pointer;
	margin-top: 2rem;
	-webkit-transition: all 0.2s ease-out;
	-moz-transition: all 0.2s ease-out;
	-o-transition: all 0.2s ease-out;
	-ms-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out;

	&:hover {
		-webkit-transition: all 0.2s ease-out;
		-moz-transition: all 0.2s ease-out;
		-o-transition: all 0.2s ease-out;
		-ms-transition: all 0.2s ease-out;
		transition: all 0.2s ease-out;
		letter-spacing: .2px;
		opacity: 0.8;
		transform: scale(1.1);
	}
}

.connect-item p {
	margin-top: 10px;
	line-height: 5px;
	font-size: 18px;
	font-family: 'SFMono-Regular', Menlo, Monaco, Consolas, 'Liberation Mono',
		'Courier New', monospace;
	color: black;
}

.connect-row a {
	display: inline-block;
}
.fa-instagram {
	width: 5rem;
	background: radial-gradient(
		circle at 30% 107%,
		#fdf497 0%,
		#fdf497 5%,
		#fd5949 45%,
		#d6249f 60%,
		#285aeb 90%
	);
	-webkit-background-clip: text;
	/* Also define standard property for compatibility */
	-webkit-text-fill-color: transparent;
}
.fa-whatsapp {
	color: #00e676;
}
.fa-telegram {
	color: #4c99db;
}

.phone_number a,
.phone_number_footer a {
	margin: 3rem 0 5rem 0;
	display: inline-block;
	color: #343434;
	transition: all 0.3s;
	font-size: 1.8rem;
	font-weight: 500;
}
.phone_number_footer {
	i {
		-webkit-transition: all 0.3s ease-out;
		-moz-transition: all 0.3s ease-out;
		-o-transition: all 0.3s ease-out;
		-ms-transition: all 0.3s ease-out;
		transition: all 0.3s ease-out;
		transform: rotateY(180deg) rotate(-15deg) translateX(-20px)
			translateY(-5px);
		position: relative;
		top: 1px;
		font-size: 34px;
		padding-left: 20px;
	}
}
</style>
