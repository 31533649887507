<template>

    <section class="" id="firstSection" :class="{visible: isVisible}"

    >
        <img v-if="isMobile" src="../../../public/img/backs/first_section_strawberries_small.jpg"
             id="img" alt=""
        >

        <img v-else src="../../../public/img/backs/first_section_strawberries.jpeg"
             id="img" alt=""
        >
        <div class="darken-image"></div>




        <a @click="to">
            <button id="get_types">Что <br>   у нас есть?</button>
        </a>

        <p class="strawberry-emoji">&#127827;</p>
        <p class="choco-emoji">&#127851;</p>
        <p class="alco-emoji">&#127870;</p>
        <p class="gift-emoji">&#127873;</p>

    </section>

</template>

<script>
    export default {
        name: 'firstSection',
        data: ()=>({
            isVisible: false,
            isMobile: true
        }),
        created() {
            if (window.screen.width > 600){
                this.isMobile = false
            }
        },

        mounted() {


            const interval =  setInterval( ()=>{
                if (document.getElementById("img").complete){
                    setTimeout(() => {
                        this.isVisible = true;
                    }, 500)
                    clearInterval(interval)

                }
            }, 500);



        },

        methods:{
                to(){
                    window.scroll({
                        top: 900,
                        left: 0,
                        behavior: 'smooth'
                    });
                },
        }
    }
</script>

<style scoped lang="less">

    @text-color: rgb(26,26, 24);
    @white: rgb(255,255, 255);
    @red: #f35858;
    @black: #343434;


    #firstSection {

        position: relative;
        width: 100%;
        button {
            -webkit-transition: -moz-transform .8s ease-out;
            -moz-transition: -webkit-transform .8s ease-out;
            transition: transform .8s ease-out ;
            position: absolute;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            transform: scale(1);
            color: @white;
            text-transform: uppercase;
            border: none;
            background-color: transparent;
            text-shadow: 0 0 35px white;
        }

        img{
            transition: all 1s;
            width: 100%;
            object-fit: cover;
        }

        .darken-image{
            transition: all 1s;
            top:0;
            bottom: 0;
            position: absolute;
            width: 100%;
        }

        .strawberry-emoji, .choco-emoji,
        .alco-emoji{
            left: 45%;
            top:40%;
            transform: scale(0);
            text-shadow: 0 0 25px white;
            width:10px;
            height: 10px;
            position: absolute;
            transition: transform 0.3s ease-in-out;
            -webkit-transition: -moz-transform .3s ease-out;
            -moz-transition: -webkit-transform .3s ease-out;
         ;
        }

        .gift-emoji{
            -webkit-transition: -moz-transform .8s ease-out;
            -moz-transition: -webkit-transform .8s ease-out;
            transition: transform .8s ease-out ;

            text-align: center;
            width:60px;
            height: 10px;
            position: absolute;
            text-shadow: 0 0 50px white;
            top: 70%;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            font-size: 50px;
        }


    }

    #firstSection.visible{
        transition-delay: 1s;

        .darken-image{
            transition: all 1s;
            background-color: rgba(0, 0, 0, .6);
        }

        button{
            -webkit-transition: -moz-transform .8s ease-out;
            -moz-transition: -webkit-transform .8s ease-out;
            transition: transform .8s ease-out ;
        }

        .strawberry-emoji{
            -webkit-transition: -moz-transform .3s ease-out;
            -moz-transition: -webkit-transform .3s ease-out;
            transition: transform .3s ease-out ;

            transition-delay: 2s;
            transform: scale(4) translateX(-8vw) translateY(7vh) rotate(-50deg);
        }
        .choco-emoji{
            -webkit-transition: -moz-transform .3s ease-out;
            -moz-transition: -webkit-transform .3s ease-out;
            transition: transform .3s ease-out ;

            transition-delay: 2s;
            transform: scale(4.1)   translateX(9vw) translateY(-6vh)  rotate(60deg);
        }



        .alco-emoji{
            -webkit-transition: -moz-transform .3s ease-out;
            -moz-transition: -webkit-transform .3s ease-out;
            transition: transform .3s ease-out ;

            transition-delay: 2s;
            transform: scale(4.5)   translateX(8vw) translateY(5vh) rotate(80deg);
        }

        .gift-emoji{
            -webkit-transition: -moz-transform .3s ease-out;
            -moz-transition: -webkit-transform .3s ease-out;
            transition: transform .3s ease-out ;

            transition-delay: 2s;
            transform: scale(0) rotate(360deg);
        }


    }


    @media screen and (max-width: 600px){

        #firstSection {
            height: 90vh;

            position: relative;
            button {
                letter-spacing: 4px;
                top: 20%;
                font-size: 2.5rem;
                font-weight: 600;
                line-height: 6rem;
                width: 50vw;

            }
            img{
                height: 90vh;
            }

            .darken-image{ height: 90vh; }



        }

        #firstSection.visible{
            transition-delay: 1s;

            img{transition-delay: 1s;}

            .darken-image{transition-delay: 1s;}

            button{
                transition-delay: 1s;
                transform: scale(1.2) ;
            }


        }
    }

    @media screen and (min-width: 601px)  and (max-width: 1200px){

        #firstSection {
            height: 65vh;
            position: relative;

            button {
                letter-spacing: 5px;
                top: 20%;
                font-size: 2.2rem;
                font-weight: bold;
                line-height: 7rem;
                width: 60vw;
            }
            img{ height: 65vh; }

            .darken-image{ height: 65vh; }



        }

        #firstSection.visible{
            transition-delay: 1s;

            img{transition-delay: 1s;}

            .darken-image{transition-delay: 1s;}

            button{
                transition-delay: 1s;
                transform: scale(1.2);
            }

        }
    }

    @media screen and (min-width: 1201px)  and (max-width: 1500px){

        #firstSection {
            height: 70vh;
            position: relative;

            button {
                letter-spacing: 5px;
                top: 20%;
                font-size: 2.4rem;
                font-weight: 600;
                line-height: 7rem;
                width: 50vw;
            }

            img{ height: 70vh; }

            .darken-image{ height: 70vh; }

        }

        #firstSection.visible{
            transition-delay: 1s;

            img{transition-delay: 1s;}

            .darken-image{transition-delay: 1s;}

            button{
                transition-delay: 1s;
                transform: scale(1.2);
            }


        }
    }

    @media screen and (min-width: 1501px) {

        #firstSection {
            height: 90vh;
            position: relative;

            button {
                letter-spacing: 5px;
                top: 25%;
                font-size: 2.7rem;
                font-weight: 600;
                line-height: 8rem;
                width: 70vw;

            }

            img{ height: 90vh; }

            .darken-image{ height: 90vh; }

        }

        #firstSection.visible{
            transition-delay: 1s;


            .darken-image{transition-delay: 1s;}

            button{
                transition-delay: 1s;
                transform: scale(1.2);
            }

        }
    }


</style>