<template>

  <div class="home main-page" >

      <FirstSection />

  <!--      секция виды букетов-->
      <SecondSection />
      <ThirdSection />
      <Section5/>
      <Section4/>

      <Section6/>

      <SocialFooter/>
  </div>

</template>

<script>
    import FirstSection from '@/components/MainPageSections/FirstSection';
    import SecondSection from "../../components/MainPageSections/SecondSection";
    import ThirdSection from '@/components/MainPageSections/ThirdSection';
    import SocialFooter from '@/components/MainPageSections/SocialFooter';
    import Section4 from '@/components/MainPageSections/Section4';
    import Section5 from '@/components/MainPageSections/Section5';
    import Section6 from '@/components/MainPageSections/Section6';

    export default {
        name: 'home',
        data: () => ({
            loading : true,
            loaded: false,
            img: false,
        }),

      components: {
        SecondSection, FirstSection, ThirdSection,
          SocialFooter,Section4, Section5, Section6
      },
    }
</script>
